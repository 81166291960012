<template>
  <el-card class="px-16 sm:px-24 py-16">
    <h1 class="font-medium text-lg mb-20">Profile</h1>

    <ValidationObserver ref="editProfile" tag="div">
      <div class="flex items-center mb-20">
        <el-input
          v-model="userData.email"
          input-label="E-mail"
          input-class="rounded"
          input-name="email"
          placeholder="Email"
          required
          rules="required|email"
          class="w-1/2 mr-16"
        />
        <el-input
          v-model="userData.full_name"
          input-label="Name"
          input-class="rounded"
          input-name="name"
          placeholder="Name"
          class="w-1/2"
        />
      </div>

      <div class="flex flex-col sm:flex-row sm:items-end mt-20">
        <el-select
          v-model="userData.role"
          select-label="Rolle"
          :reduce="role => role.value"
          :options="rolleOptions"
          required
          rules="required"
          class="flex-1"
        />
        <el-checkbox v-model="userData.enable_order_email" :disabled="loading" class="mb-12 ml-24">Enable order email</el-checkbox>
      </div>
    </ValidationObserver>

    <el-button
      type="primary"
      :loading="loading"
      class="mt-20 ml-auto"
      @click="onEdit"
    >
      Save
    </el-button>
  </el-card>
</template>

<script>
import { mapActions } from 'vuex'
const ElCard = () => import('@/components/shared/ELCard')
const ElInput = () => import('@/components/shared/ELInput')
const ElSelect = () => import('@/components/shared/ELSelect')
const ElButton = () => import('@/components/shared/ELButton')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')

export default {
  name: 'UserEditProfile',

  components: { ElCard, ElInput, ElSelect, ElButton, ElCheckbox },

  props: {
    userData: {
      type: Object,
      default: () => {
        return {
          email: '',
          full_name: '',
          role: ''
        }
      }
    },

    loading: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      email: '',
      rolle: '',
      rolleOptions: [
        { label: 'Administrator', value: 'admin' },
        { label: 'Advertiser', value: 'advertiser' }
      ]
    }
  },

  methods: {
    ...mapActions(['showNotification']),

    onEdit () {
      this.$refs.editProfile.validate().then(isValid => {
        if (!isValid) {
          const errors = []

          // generate array of arrows
          Object.keys(this.$refs.editProfile.errors).map(error => {
            errors.push(...this.$refs.editProfile.errors[error])
          })

          this.showNotification({ type: 'error', message: errors })
          return false
        }
        this.$emit('edit-user')
      })
    }
  }
}
</script>
